import {Moment} from 'moment'
import {BusinessLineTypes} from '../../common/types'
import {Customer} from '../../Organisms/Customers/Customers.types'
// eslint-disable-next-line import/no-cycle
import {Delivery, Haulier, Link} from '../Finance/Invoices/Delivery.types'

// Generated by https://quicktype.io

export interface SiteContact {
  siteContactName: string
  siteContactNumber: string
}

export interface Order {
  checkSum?: string
  /**
   * Reference to the customer
   */
  customer: Customer
  /**
   * Customer reference text used by customer to identify the order, e.g. customer purchase
   * order reference
   */
  customerReference?: null | string
  /**
   * Site contact name and number
   */
  contactInformation?: SiteContact
  contractNumber?: string
  /**
   * Indicates if further exection of the order is on hold.
   */
  isHalted?: boolean
  deliveries?: Delivery[]
  deliveriesFromOrder?: Delivery[]
  lineItems: OrderLineItem[]
  /**
   * Unique Identifier for the group of orders
   */
  groupId: string
  /**
   * Remark whether the group contains more than one order
   */
  isHaveMoreThanOne: boolean
  /**
   * List of all orders in group
   */
  orderIds: string[]
  /**
   * Unique Identifier for the order
   */
  orderId: string
  /**
   * Reference to business key of an order in the backend system
   */
  orderNumber: string
  /**
   * Status information of the order
   */
  orderStatus: OrderStatus
  /**
   * The date the order was placed
   */
  placedDate?: string | Date | Moment
  /**
   * Reference to a project/contract this order is linked to
   */
  projectId?: null | string
  /**
   * Reference to a business key of project/contract this order is linked to
   */
  projectNumber?: null | string
  /**
   * Reference to the order source (order placed via HUB or not)
   */
  purchaseOrderType?: null | string
  /**
   * Attribute to check if the order line item is handled via SyncroTess or not
   */
  route?: null | string
  /**
   * Delivery plant distance
   */
  distance?: number
  /**
   * The requested schedule of the delivery
   */
  schedules: DeliverySchedule[]
  /**
   * The address of the destination/site the order is delivered to
   */
  shippingAddress: ShippingAddress
  /**
   * The date the order is expected to be executed
   */
  shippingDate: string
  /**
   * Indicates if an order is delivered to the customer or picked-up by the customer
   */
  shippingType: ShippingType
  supplementItems?: SupplementItem[]
  deliveryProgress: DeliveryProgressType
  businessLine?: BusinessLineTypes
  modeOfTransport?: ModeOfTransportType
  /**
   * Is test is booked for this order
   */
  isTestBooked: boolean
  /**
   * sales org ID where order is ordered
   */
  orgUnitId?: string
  orderPlacerName?: string
  shipFromUtcOffset?: string
  shipToUtcOffset?: string
  loadingNumber?: string
  qrCode?: string
  recipeNumber?: string
  unloadingMethod?: string
  orderChanges?: OrderChange[]
  note?: Note
  links?: Link[]
  haulier?: Haulier
  headerDivisionId?: string
  orderCancelerEmail?: string
  orderCancelerName?: string
  isCarbonSavingProduct?: boolean
}

export interface OrderLineItem {
  /**
   * Reference to the position number of the row in the order
   */
  itemNumber: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  materialAttributes?: any[]
  /**
   * Description of the material of the order
   */
  materialDescription: string
  /**
   * Reference to business key of the material in the backend system
   */
  materialNumber: string
  /**
   * quantity ordered
   */
  quantity: number
  /**
   * The unit of measurement of the quantity order
   */
  quantityUom: string
  arrivalSpacing: string
  quantityPerHour: number
  quantityChangeExpected?: boolean
  customerMaterialDescription?: string
}

/**
 * Status information of the order
 */
export enum OrderStatus {
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  InProgress = 'inProgress',
  PartiallyDelivered = 'partiallyDelivered',
  PartiallyShipped = 'partiallyShipped',
  Pending = 'pending',
  Reserved = 'reserved',
  Shipped = 'shipped',
  Rejected = 'rejected',
  Closed = 'closed',
  PaymentPending = 'paymentPending'
}

/**
 * The address of the destination/site the order is delivered to
 */
export interface ShippingAddress {
  /**
   * address informationn of the site
   */
  city?: null | string
  /**
   * address informationn of the site
   */
  country?: null | string
  /**
   * Reference to the customer
   */
  customerId: string
  /**
   * Latitude of the site
   */
  lat?: null | string
  /**
   * Longitute of the site
   */
  lng?: null | string
  /**
   * address informationn of the site
   */
  postalCode?: null | string
  /**
   * Unique identifier of the site
   */
  siteId: string
  /**
   * Textual description of the project that helps to clearly identify a given site
   */
  siteName?: null | string
  /**
   * buiness key of the site
   */
  siteNumber?: null | string
  /**
   * address informationn of the site
   */
  state?: null | string
  /**
   * Street address informationn of the site
   */
  street?: null | string
}

/**
 * Indicates if an order is delivered to the customer or picked-up by the customer
 */
export enum ShippingType {
  Collect = 'collect',
  Deliver = 'deliver'
}

export interface DeliveryProgressType {
  arrivedDeliveries: number
  arrivedQuantity: number
  deliveredDeliveries: number
  deliveredQuantity: number
  shippedDeliveries: number
  shippedQuantity: number
}

export interface DeliverySchedule {
  scheduleStartDate: Date | string
  scheduleEndDate: Date | string
  scheduleEarliestStartDate: Date | string
  arrivalSpacing: string
  quantityChangeExpected: boolean
  quantityPerHour: number
  scheduleNumber: string
  scheduleQuantity: number
}

export enum ModeOfTransport {
  Truck = 'truck',
  Train = 'train',
  Ship = 'ship',
  Others = 'others'
}
export type ModeOfTransportType =
  | ModeOfTransport.Train
  | ModeOfTransport.Truck
  | ModeOfTransport.Ship
  | ModeOfTransport.Others

export interface SearchOrdersQueryParameters {
  startDate?: string
  endDate?: string
  siteId?: string
  customerId?: string
  orderNumber?: string
  projectId?: string
  orderStatus?: string
  orgUnitId?: string
  businessLine?: string
  sortedBy?: string
  expand?: string
  limit?: number
  skip?: number
  isTestBooked?: boolean
  countryId?: string
  shippingType?: string
  excludeCancelledDeliveries?: boolean
  excludeCancelledOrders?: boolean
  poNumber?: string
  includeDeliveryInstructions?: boolean

  orderId?: string
}

export enum OrderChangedBy {
  CSC = 'csc',
  Customer = 'customer'
}

export enum OrderChangedField {
  UnloadingMethod = 'UnloadingMethod',
  UnloadingSpeed = 'UnloadingSpeed',
  EarliestLoadDateTime = 'EarliestLoadDateTime',
  FirstLoadDateTime = 'FirstLoadDateTime',
  ArrivalSpacing = 'ArrivalSpacing',
  QuantityPerhour = 'QuantityPerhour',
  UnloadingTime = 'UnloadingTime',
  Quantity = 'Quantity',
  OrderReason = 'OrderReason'
}

export interface OrderChange {
  changeNumber: string
  changedBy: OrderChangedBy
  fieldDescription: OrderChangedField
  oldValue: string
  newValue: string
  changeDate?: string
}

export interface OrdersAnalytics {
  userId: string | undefined
  userMail: string | null | undefined
  userCountry: string | null | undefined
  userRoles: unknown[] | undefined
  userIsInternal: boolean | undefined
  customerId: string | undefined
  customerName: string | undefined
  downloadedItemBusinessLine: string | undefined
  salesOrg: string | undefined
  orderId: string | undefined
  details: boolean | undefined
}

export enum PurchaseOrderType {
  Hub = 'YHUB'
}

export interface Note {
  headerTexts: string[]
  itemTexts: string[]
}

export interface SupplementItem {
  relatedItemNumber: string
  itemNumber: string
  itemName: string
  quantity: number
  quantityUom: string
}

export interface MaterialLocation {
  deliveryId: string | undefined
  deliveryNumber: string
  estimatedTimeOfArrival: string | Date
  lastUpdated: string | Date
  lat: string | null
  lng: string | null
  source: string
  truckId: string
  truckName: string
}

export interface MaterialLocations {
  materialLocations?: MaterialLocation[]
}
