import {
  getUtcOffset,
  PrimaryCell,
  dateFormatter,
  timeFormatter,
  Typography,
  TableColumnType
} from '@hconnect/uikit'
import {Box, makeStyles, Link} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {BusinessLineType} from '../../common/types'
import WarningIcon from '../../Molecules/AdditionalIcons/WarningIcon'
import MaterialDescription from '../../Molecules/MaterialDescription'
import MiniProgressBar from '../../Molecules/MiniProgressBar'
import {ModeOfTransportIcon} from '../../Molecules/ModeOfTransport'
import StatusIcon from '../../Molecules/StatusIcons'
import StatusTooltip from '../../Molecules/StatusTooltip'
import {Features, useFeaturesState} from '../../Organisms/Features'
import {statusFromOrderStatus} from '../../Organisms/Orders'

import {RequestedDate, TimezoneWarning, OrderNumber} from './components'
import {showProgressBar} from './hooks/useProgressBarVisibility'
import {QuantityUomToSymbolComponent} from './Order.components'
import {Order} from './Order.types'
import {
  getIsCEM,
  getIsRMC,
  getIsAGG,
  getEventLocationOffset,
  isOrderExpandable,
  showWarning
} from './Order.utils'

const useStyles = makeStyles({
  multipleDownloadStatus: {
    fontSize: '12px',
    color: 'rgba(0, 55, 77, 0.6)'
  }
})

export const OrderRows = ({t, language, country}) => {
  const classes = useStyles()
  const history = useHistory()
  const {getFeature} = useFeaturesState()
  // const isOrderDetailsEnabled = getFeature('OrderDetails')
  const isOrderDetailsEnabled = true
  const isGroupedDeliveries = getFeature('OrderIntakeGroupedDeliveries')
  const isDeliveryNotesDisabled = getFeature('DisableViewDeliveryNotes')
  const isTicketReplacingOrderNumber = getFeature('CustomerDeliveryNumberForOrderNumber')
  const useHaulierView = getFeature('UseHaulierView')

  const featureFlagOrder = (country: string, rowsPool: {[key: string]: TableColumnType}) => {
    return [
      rowsPool.shippingDate,
      ...(useHaulierView
        ? [rowsPool.customerName]
        : isTicketReplacingOrderNumber
          ? [rowsPool.ticketNo]
          : [rowsPool.customerRef]),
      rowsPool.project,
      rowsPool.material,
      rowsPool.progress,
      ...(getFeature('RMCTestReports') ? [rowsPool.tested] : []),
      rowsPool.orderStatus
    ]
  }
  const rowsPool: {[key: string]: TableColumnType} = {
    shippingDate: {
      field: 'shippingDate',
      headerName: t('order.requestedDate'),
      sortable: true,
      // eslint-disable-next-line complexity
      renderCell: (item: Order) => {
        const isCEM = getIsCEM(item)
        const isRMC = getIsRMC(item)
        const isAGG = getIsAGG(item)

        const {showWarningIcon, msg} = showWarning(item, t, isDeliveryNotesDisabled)

        const scheduleStartDate = item.schedules
          ? item.schedules[0].scheduleStartDate
          : item.shippingDate

        const flowRateAvailable = (order: Order) =>
          order.schedules && Array.isArray(order.schedules) && !!order.schedules[0].quantityPerHour
        const flowRateSpacingFormatter: React.FC<Order> = (order: Order) => {
          if (order.schedules && Array.isArray(order.schedules)) {
            return flowRateAvailable(order) ? (
              <>
                {order.schedules[0].quantityPerHour}{' '}
                <QuantityUomToSymbolComponent uom={item.lineItems[0].quantityUom} />{' '}
                {t('order.schedules.flowRate')}
              </>
            ) : (
              <>
                {moment.duration(order.schedules[0].arrivalSpacing).asMinutes()}{' '}
                {t('order.schedules.spacing')}
              </>
            )
          }
          return null
        }

        const userOffset = getUtcOffset(item.shippingDate)
        const eventLocationOffset = getEventLocationOffset(item)

        return (
          <>
            <PrimaryCell
              data-test-id={`DataTable-column-order-${getItemIdentifier(item)}-requested-date`}
            >
              {isCEM || isAGG ? (
                <RequestedDate
                  t={t}
                  order={item}
                  language={language}
                  userOffset={userOffset}
                  eventLocationOffset={eventLocationOffset}
                  hideOvernightWarning={isAGG ? true : false}
                />
              ) : (
                <>
                  {`${dateFormatter(item.shippingDate, language, eventLocationOffset)}
                  ${timeFormatter(scheduleStartDate, language, eventLocationOffset)}`}
                  <TimezoneWarning
                    t={t}
                    order={item}
                    userOffset={userOffset}
                    eventLocationOffset={eventLocationOffset}
                  />
                </>
              )}
            </PrimaryCell>
            {isRMC && item.schedules && getFeature('FlowRateOrder') ? (
              <Typography color="secondary" variant="subtitle1">
                {flowRateSpacingFormatter(item) || ''}
              </Typography>
            ) : null}
            {msg ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                data-test-id={`warning-message-${getItemIdentifier(item)}`}
              >
                {showWarningIcon ? (
                  <>
                    <WarningIcon height={16} width={16} fill="rgba(0, 55, 77, .5)" size="inherit" />
                    <Box width={6} data-test-id="warning-icon" />
                  </>
                ) : null}
                <Typography classes={{root: classes.multipleDownloadStatus}}>{msg}</Typography>
              </Box>
            ) : null}
          </>
        )
      },
      flex: 4
    },
    ticketNo: {
      field: 'ticketNo',
      headerName: t('order.expand.deliveryNumber'),
      headerAlign: 'left',
      renderCell: (item: Order) => {
        const ticketNo =
          item.deliveries && item.deliveries.length > 0 && item.deliveries[0].customerDeliveryNumber
            ? item.deliveries[0].customerDeliveryNumber
            : t('missingValue')
        return <PrimaryCell>{ticketNo}</PrimaryCell>
      },
      flex: 2
    },
    customerRef: {
      field: 'customerRef',
      headerName: t('order.orderNumber'),
      headerAlign: 'left',
      renderCell: (item: Order) => {
        const PONumber = item.customerReference ? item.customerReference : t('missingValue')
        return (
          <>
            <Features name="ShowPONumber">
              <PrimaryCell>{PONumber}</PrimaryCell>
            </Features>
            <Typography color="secondary" variant="subtitle1">
              {!item.isHaveMoreThanOne && (
                <OrderNumber order={item} entryPoint="Order details page" t={t} includeItemNumber />
              )}
            </Typography>
          </>
        )
      },
      flex: 3
    },
    customerName: {
      field: 'customerName',
      headerName: t('order.customerName'),
      headerAlign: 'left',
      renderCell: (item: Order) => {
        return (
          <>
            <PrimaryCell>{item.customer.customerName}</PrimaryCell>
          </>
        )
      },
      flex: 2
    },
    project: {
      field: 'project',
      headerName: t('order.project'),
      renderCell: (item: Order) => {
        const {siteName, street, city} = item.shippingAddress
        return (
          <>
            <PrimaryCell>{siteName}</PrimaryCell>
            <Typography color="secondary" variant="subtitle1">
              {street}, {city}
            </Typography>
          </>
        )
      },
      flex: 5
    },
    material: {
      field: 'material',
      headerAlign: 'left',
      headerName: t('order.material'),
      renderCell: (item: Order) => {
        const {materialDescription, customerMaterialDescription} = item?.lineItems[0] || {}
        return (
          <PrimaryCell noWrap={false}>
            <MaterialDescription
              materialDescription={materialDescription}
              customerMaterialDescription={customerMaterialDescription}
              isCarbonSavingProduct={item.isCarbonSavingProduct}
            />
          </PrimaryCell>
        )
      },
      flex: 3
    },
    progress: {
      field: 'progress',
      headerName: t('order.orderProgress'),
      headerAlign: 'left',
      flex: 3,
      renderCell: (item: Order) => {
        const {businessLine, modeOfTransport, shippingType} = item
        const {deliveredQuantity} = item.deliveryProgress || {}
        const {quantity} = item.lineItems[0]

        const isCountryProgressEnabled = getFeature('OrderProgressBar')
        const showOrderProgressBarCollect = getFeature('OrderProgressBarCollect')
        const isProgressBarVisible = showProgressBar(
          item,
          isCountryProgressEnabled,
          showOrderProgressBarCollect
        )
        return (
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={isProgressBarVisible ? 'space-between' : 'flex-end'}
              alignItems="center"
            >
              <PrimaryCell>
                {!isProgressBarVisible ? (
                  <>
                    {quantity} <QuantityUomToSymbolComponent uom={item.lineItems[0].quantityUom} />
                  </>
                ) : (
                  <>
                    {getIsCEM(item) ? quantity : quantity + '/' + deliveredQuantity}{' '}
                    <QuantityUomToSymbolComponent uom={item.lineItems[0].quantityUom} />
                  </>
                )}
              </PrimaryCell>
              <Box ml={1} alignItems="center" display="flex">
                <ModeOfTransportIcon
                  modeOfTransport={modeOfTransport}
                  shippingType={shippingType}
                  businessLine={businessLine}
                  uom={item.lineItems[0].quantityUom}
                />
              </Box>
            </Box>
            {isProgressBarVisible ? <MiniProgressBar order={item} /> : null}
          </Box>
        )
      }
    },
    tested: {
      field: 'tested',
      headerName: t('order.orderTested'),
      headerAlign: 'center',
      flex: 1,
      renderCell: (item: Order) => {
        const booked = item.isTestBooked ? 'booked' : 'none'
        if (item.businessLine !== BusinessLineType.RMC || booked === 'none') {
          return <PrimaryCell>{t(`order.isTestBooked.${booked}`)}</PrimaryCell>
        }
        if (!isOrderExpandable(item)) {
          return <PrimaryCell>{t(`order.isTestBooked.${booked}`)}</PrimaryCell>
        }
        return (
          <Link
            style={{cursor: 'pointer'}}
            onClick={() => {
              history.push({
                pathname: '/certificates/rmc',
                search: `?customerId=${item.customer.customerId}&orderNumber=${item.orderNumber}`
              })
            }}
          >
            {booked}
          </Link>
        )
      }
    },
    orderStatus: {
      field: 'orderStatusAggregate',
      headerName: t('order.orderStatus'),
      headerAlign: 'center',
      flex: 3,
      renderCell: (item: Order) => {
        const status = statusFromOrderStatus(item.orderStatus, item.shippingType)
        const detailsEnabled = isOrderDetailsEnabled
        return (
          <PrimaryCell>
            <Box display="flex" alignItems="center" justifyContent="space-around">
              <StatusTooltip
                title={t(`order.orderStatusLabel.tooltip.${status}`)}
                placement="right-start"
                data-test-id={`order-status-tooltip-${getItemIdentifier(item)}`}
              >
                <Box display="flex" alignItems="center">
                  <StatusIcon status={item.orderStatus} shippingType={item.shippingType} />
                  {!detailsEnabled ? (
                    <>
                      <Box mx={0.5} />
                      <PrimaryCell>{t(`order.orderStatusLabel.${status}`)}</PrimaryCell>
                    </>
                  ) : null}
                </Box>
              </StatusTooltip>
            </Box>
          </PrimaryCell>
        )
      }
    }
  }
  const getItemIdentifier = (item: Order): string => {
    return isGroupedDeliveries ? item.groupId : item.orderId
  }

  return featureFlagOrder(country, rowsPool)
}
